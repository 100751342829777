$primary: #ebad1a;
$secondary: #123d71;

//lazy loading animation
.lazy {
	opacity: 0;
	will-change: opacity;
}

.loaded {
	animation: fadeIn 0.5s ease-in;
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

.visible-xs, .visible-sm, .visible-md, .visible-lg {
	display: none !important;
}

@media (min-width: 1200px) {
	.visible-lg {
		display: block !important;
	}
}

@media (max-width: 1199px) and (min-width: 992px) {
	.visible-md {
		display: block !important;
	}
}

.affix {
	position: fixed;
}

.navbar-toggle {
	position: relative;
	float: right;
	margin-right: 15px;
	padding: 9px 10px;
	margin-top: 8px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
}

.navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px;
}

.navbar-desctop-menu.navbar-desctop-menu > li {
	margin-left: 2.5em;
}


.navbar-toggle:focus {
	outline: none;
}

.navbar-collapse.in {
	overflow-y: auto;
}
.collapse.in {
	display: block !important;
}

.main-contacts.main-contacts:after {
	background: -webkit-linear-gradient(bottom, rgba(39,39,39,0.70) 0%,rgba(39,39,39,0.70) 26%,rgba(39,39,39,0.3) 100%);
	background: linear-gradient(to top, rgba(39,39,39,0.70) 0%,rgba(39,39,39,0.70) 26%,rgba(39,39,39,0.3) 100%);
}

@media ( min-width: 1200px ) and ( max-width: 1330px ) {
	.navbar-desctop-menu.navbar-desctop-menu.navbar-desctop-menu > li {
		margin-left: 1.9em;
	}
}

.header-phone.header-phone {
	left: 35vmin;
	top: 6vmin;
}

.navbar-nav-mobile.navbar-nav-mobile {
	font-size: 1.25em;
}


.vertical-panel {
	display: none;
}

.vertical-panel-content {
	bottom: auto;
	top: 10vmin;
}

.main {
	min-height: auto;
}

.collapse.in {
	width: 100%;
}

.vertical-panel-title {
	display: none;
}

.service-item-home {
	h4 {
		margin: 0;
	}
}


.entry-title {
	font-size: 2.8em;
}

.entry-text {
	font-size: 1.45em;
	line-height: 1.45;
}

.social-icon {
	max-width: 35px;
	height: auto;
	@media( max-width: 768px ) {
		max-width: 25px;
	}
}


.navbar-toggle {
	margin-top: -12px;
}

@media( max-width: 768px ) {
	.brand-panel {
		height: 46.7vmin;
	}
}

.navbar-collapse {
	margin-right: 0px !important;
	width: 100% !important;
}

.navbar-nav-mobile {
	padding-top: 0;
}

.slide-number sup {
	color: white;
}

.slide-number .current-number {
	font-size: 5.5vmin;
	letter-spacing: 0.1em;
	line-height: .4;
}

.vertical-panel-title {
	color: white;
	font-size: 14px;
	letter-spacing: .7em;
	margin-left: 7px;
	margin-top: 260px;
}

.vertical-panel-info .line {
	width: 2px;
	background-color: white;
	height: 6.5vmin;
	margin-top: 0;
	margin-left: 8px;
}

.social-list li {
	margin-top: 2vmin;
}

.vertical-panel-info {
	margin-bottom: 2.5vmin;
}

.brand-panel {
	width: 31vmin;
}

.vertical-panel-content {
	padding-left: 2.5em;
	text-align: left;
}

@media( min-width: 991px ) {
	// .slider {
	// 	height: 90vmin;
	// 	position: relative;
	// }
	// .vertical-panel {
	// 	bottom: auto;
	// 	top: 0;
	// 	height: 90vmin;
	// }
	// .vertical-panel-content {
	// 	bottom: 17vmin !important;
	// }
	// .rev_slider_wrapper, .forcefullwidth_wrapper_tp_banner, .tp-fullwidth-forcer {
	// 	height: 90vmin !important;
	// }
}

.slider .arrow-left, .slider .arrow-right {
	&:before {
		display: none;
	}
	&:after {
		content: "\f104";
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		transform: translate(0, 0);
		background: none;
		color: #787a7f;
		font-size: 2.5rem;
		opacity: 1;
		transition: all .3s ease-in;
	}
	&:hover:after {
		color: $primary;
	} 
}

.slider .btn {
	padding: 1.3em 2.2em 1.25em !important;
	i {
		display: none;
	}
}

@media( min-width: 768px ) {
	.arrow-left, .arrow-right {
		top: 41vmin;
	}
}

.arrow-right {
	margin-left: 0;
	left: 24vmin;
}

.arrow-left {
	left: 22vmin;
}

.slider .arrow-right:after {
	content: "\f105";
}

.slotholder:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(9, 15, 40, .5);
	@media( min-width: 991px ) {
		background: linear-gradient(to left, 
			rgba(9, 15, 40, .3) 0%, rgba(9, 15, 40, .3) 10%, 
			rgba(9, 15, 40, .75) 10%, rgba(9, 15, 40, .75) 60%,  
			rgba(9, 15, 40, .3) 60%, rgba(9, 15, 40, .3) 70%, 
			rgba(9, 15, 40, .75) 70%,rgba(9, 15, 40, .75) 100%);
	}
}

@media( max-width: 991px ) {
	.sm-break {
		display: block;
	}
}

.col-about-title {
	font-size: 1.575em;
}

.col-about-img {
	padding-left: 15px;
	left: 0;
	.eye {
		width: 100px;
	}
	img {
		max-width: 100% !important;
	}
}

@media (min-width: 768px) {
	.col-about-spec {
		padding: 0 2.4em;
	}
}

.service-item {
	margin-bottom: 1em;
}

.project figure:after {
	background-color: $secondary;
}

.project-zoom {
	background-color: $primary;
	text-align: center;
}

.project-zoom:after {
	background: url(/img/icon-door.png) 0 0 no-repeat;
	width: 1.9em;
	top: 47%;
}

.project-zoom:before {
	content: "VEZI PRODUS";
	position: absolute;
	left:50%;
	top: 61%;
	max-width: 70%;
	color: white;
	text-transform: uppercase;
	transform: translateX(-50%);
	font-family: 'montserratsemibold';
	line-height: 1;
	font-size: .8em;
}

.info-item {
	img {
		height: 55px;
		width: auto;
		margin-bottom: 10px;
	}
	@media( max-width: 768px ) {
		text-align: center;
		margin-bottom: 30px;
	}
}

.experience .text-parallax {
	background-size: 100%;
}

.footer {
	font-size: 15px;
	.row {
		justify-content: space-between;
	}
	.reserved a {
		color: $secondary;
	}
	.links a {
		color: lighten( #747474, 20);
		padding: 0 10px;
		display: inline-block;
	}
	@media( max-width: 768px ) {
		margin-top: -30px;
		.reserved {
			order: 5;
		}
		.links {
			width: 100%;
			margin-bottom: 10px;
			a {
				display: block;
				text-align: center;
				width: 100%;
				margin-bottom: 5px;
			}
		}
	}
}

.btn-shadow-2 {
	box-shadow: 0 15px 34px rgba(127,134,196,0.5);
}

.col-address {
	.float-left {
		min-width: 50%;
		margin-bottom: 15px;
	}
	h5 {
		font-family: 'Oswald', sans-serif;
		line-height: 1;
		font-size: 1em;
		font-weight: normal;
		color: #123d71;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	a {
		color: #757575;
	}
}

.bg-gray {
	background-color: #f2f2f2 !important;
}

.categories {
	.img {
		width: 100%;
		padding-bottom: 200%;
		background: #fff url('/img/soral-bg6.jpg') no-repeat center;
		background-size: cover;
	}
}

.showroom-item {
	text-transform: uppercase;
	color: white;
	width: 100%;
	padding-bottom: 100%;
	height: 0;
	position: relative;
	overflow: hidden;
	&.yellow .img {
		background: #fff url('/img/door-yellow.jpg') no-repeat center;
		background-size: cover;
	}
	&.blue .img {
		background: #fff url('/img/door-blue2.jpg') no-repeat center;
		background-size: cover;
	}
	.img {
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		padding-bottom: 100%;
		transition: all .4s ease-out;
	}
	.text {
		position: absolute;
		z-index: 3;
		bottom: 40px;
		left: 50px;
	}
	&:after {
		content: ""; 
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		padding-bottom: 100%;
		z-index: 2;
	}
	&.yellow:after {
		background-image: linear-gradient(to bottom, rgba(235, 173, 26, .6) 0%, rgba(235, 173, 26, .9) 100%);
	}
	&.blue:after {
		background-image: linear-gradient(to bottom, rgba(18, 61, 113, .6) 0%, rgba(18, 61, 113, .9) 100%);
	}
	.top {
		font-family: 'montserratlight';
		font-size: .95rem;
		letter-spacing: 3px;
	}
	.bottom {
		font-family: 'Oswald', sans-serif;
		font-weight: 300;
		font-size: 1.9em;
		letter-spacing: 0.2em;
		text-transform: uppercase;
	}
	.top, .bottom {
		line-height: 1.05;
		color: white !important;
	}
	&:hover .img {
		transform: scale(1.1);
	}
}
.main-projects, .main-contacts {
	padding: 24vmin 0 24vmin;
}

.categories .fade-title-left {
	left: 21%;
}

.product-image {
	padding-bottom: 120%;
}

.products-carousel .project figure:after {
	background-color: transparent;
}

.spacer {
	height: 1px;
	width: 100%;
	background-color: rgba(0,0,0,.1);
	margin: 10px 0;
}

.desc {
	&__title:after {
		content: "";
		background-color: $primary;
		display: block;
		width: 70px;
		height: 5px;
		margin-top: 5px;
		border-radius: 2px;
	}
	&__colors {
		.color {
			width: calc(25% - 10px);
			height: 100px;
			float: left;
			margin: 0 10px 10px 0;
		}
	}

	&__category {
		color: white !important;
		background-color: $primary;
		display: inline-block;
	}

	&__text {
		font-size: .9rem;
	}

	&__info {
		font-size: 1rem;
	}
}

.section-subtitle {
	max-width: 800px;
	margin: 15px auto 30px;
	text-align: center;
}

.pbox {
	text-decoration: none !important;
	margin: 30px 20px;
	display: inline-block;
	text-align: center;
	background-color: white;
	padding: 20px 0;
	box-shadow: 0 0 15px 0 rgba(200, 200, 200, .5);
	a:hover {
		text-decoration: none;
	}
	&__title {
		font-size: 1.3rem;
		color: $secondary !important;
		margin-bottom: 10px;
		padding: 0 15px 5px 0;
		display: block;
	}
	&__image {
		display: block;
		padding-bottom: 60%;
		background: #fff url("/img/usi10.jpg") center;
		background-size: cover;
	}
	&__category {
		font-size: .9rem;
		color: white !important;
		background-color: $primary;
		margin: 10px 25px 9px 25px;
		padding: 1px 3px;
		display: block;
	}
	&__desc {
		font-size: .9rem;
		color: #747474 !important;
		padding: 0 25px;
		line-height: 1.5;
		text-align: justify;
	}
	&__button {
		font-size: .9rem;
		margin: 10px 15px;
		border: 1px solid $primary;
		padding: 8px 10px;
		border-radius: 20px;
		display: block;
		&:hover {
			background-color: $primary;
			transition: all .2s ease-in-out;
			color: white;
		}
	}
}

.related-products .mfp-arrow-right:after {
	border-left: 17px solid $secondary;
}
.related-products .mfp-arrow-left:after {
	border-right: 17px solid $secondary;
}

@media (min-width: 768px) {
	.related-products .owl-next {
		right: -2.4em;
	}
	.related-products .owl-prev {
		left: -2.4em;
	}
}

.bg-vector {
	background: url('/img/vector-bg.png') right no-repeat;
	background-size: 500px auto;
	&.left {
		background: url('/img/vector-bg-rev.png') left no-repeat;
	}
}

.fade:not(.show) {
	opacity: 1;
}

.modal {
	opacity: 0 !important;
	transition: opacity 200ms ease-in-out !important;
	background-color: rgba(0 ,0 ,0, .5);
	.modal-dialog {
		transition: none !important;
	}
	&.in {
		opacity: 1 !important;
	}
}

@media (min-width: 992px) {
	.modal-dialog {
		max-width: 900px;
		margin: 1.75rem auto;
	}
}

.modal-body {
	padding-bottom: 5px;
}

.modal .close {
	font-size: 2rem;
	color: $secondary;
}

.address {
	padding: 40px 0;
	&__img {
		height: 50px;
		width: auto;
	}
	&__text {
	}
	&__head {
		margin-left: 10px;
		font-size: 1.3rem !important;
		letter-spacing: 3px;
	}
}

@media(min-width: 992px) {
	.mx-neg {
		margin-left: -15px;
		margin-right: -15px;
	}
	.br-s {
		border-right: 1px solid $secondary;
	}
}


.smenu {
	box-shadow: 0 0 15px 0 rgba(200, 200, 200, .5);
	padding: 20px 25px;
	&__title {
		font-size: 2rem;
	}
	&__option {
		display: block;
		color: $secondary;
		font-family: 'Oswald', sans-serif;
		font-weight: 400;
		font-size: 1.1em;
		letter-spacing: 0.22em;
		text-transform: uppercase;
		padding: 3px 0;
	}
}

@media( max-width: 768px ) {
	.vertical-panel-info.vertical-panel-info {
		margin-bottom: 12.5vmin !important;
	}
	.brand-panel {
		height: 55.7vmin;
	}
}

.catalog {
	text-align: center;
	padding: 10px 15px 15px 15px;
	border-radius: 4px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	transition: all .2s ease-out;
	margin-bottom: 30px;
	&:hover {
		box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
		transform: translateY(-10px);
	}
	&__title {
		color: $secondary;
		font-weight: 700;
		margin-bottom: 5px;
	}
	&__img {

	}
}

.pbox2 {
	width: 100%;
	.pbox__title {
	}
	.pbox__image {
		padding-bottom: 80%;
	}
	.pbox__category {
	}
	.pbox__desc {
		display: none;
	}
	.pbox__button {
	}
}

.img-fluid, .img-responsive {
	max-width: 100%;
	height: auto;
}

.project-details-item:nth-child(1) .project-details-title:before {
	right: -4.4em;
	left: auto;
}

.project-details-item .project-details-info {
	background-color: $secondary;
}

@media( min-width: 992px )  and ( max-width: 1300px ) {
	.header-phone {
		display: none;
	}
}

@media( min-width: 992px )  and ( max-width: 1200px ) {
	.navbar-desctop-menu.navbar-desctop-menu > li {
		margin-left: 1.5em;
	}
}

