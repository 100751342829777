@charset "UTF-8";

.lazy {
  opacity: 0;
  will-change: opacity;
}

.loaded {
  animation: fadeIn 0.5s ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .visible-md {
    display: block !important;
  }
}

.affix {
  position: fixed;
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-desctop-menu.navbar-desctop-menu > li {
  margin-left: 2.5em;
}

.navbar-toggle:focus {
  outline: none;
}

.navbar-collapse.in {
  overflow-y: auto;
}

.collapse.in {
  display: block !important;
}

.main-contacts.main-contacts:after {
  background: -webkit-linear-gradient(bottom, rgba(39, 39, 39, 0.7) 0%, rgba(39, 39, 39, 0.7) 26%, rgba(39, 39, 39, 0.3) 100%);
  background: linear-gradient(to top, rgba(39, 39, 39, 0.7) 0%, rgba(39, 39, 39, 0.7) 26%, rgba(39, 39, 39, 0.3) 100%);
}

@media (min-width: 1200px) and (max-width: 1330px) {
  .navbar-desctop-menu.navbar-desctop-menu.navbar-desctop-menu > li {
    margin-left: 1.9em;
  }
}

.header-phone.header-phone {
  left: 35vmin;
  top: 6vmin;
}

.navbar-nav-mobile.navbar-nav-mobile {
  font-size: 1.25em;
}

.vertical-panel {
  display: none;
}

.vertical-panel-content {
  bottom: auto;
  top: 10vmin;
}

.main {
  min-height: auto;
}

.collapse.in {
  width: 100%;
}

.vertical-panel-title {
  display: none;
}

.service-item-home h4 {
  margin: 0;
}

.entry-title {
  font-size: 2.8em;
}

.entry-text {
  font-size: 1.45em;
  line-height: 1.45;
}

.social-icon {
  max-width: 35px;
  height: auto;
}

@media (max-width: 768px) {
  .social-icon {
    max-width: 25px;
  }
}

.navbar-toggle {
  margin-top: -12px;
}

@media (max-width: 768px) {
  .brand-panel {
    height: 46.7vmin;
  }
}

.navbar-collapse {
  margin-right: 0px !important;
  width: 100% !important;
}

.navbar-nav-mobile {
  padding-top: 0;
}

.slide-number sup {
  color: white;
}

.slide-number .current-number {
  font-size: 5.5vmin;
  letter-spacing: 0.1em;
  line-height: 0.4;
}

.vertical-panel-title {
  color: white;
  font-size: 14px;
  letter-spacing: 0.7em;
  margin-left: 7px;
  margin-top: 260px;
}

.vertical-panel-info .line {
  width: 2px;
  background-color: white;
  height: 6.5vmin;
  margin-top: 0;
  margin-left: 8px;
}

.social-list li {
  margin-top: 2vmin;
}

.vertical-panel-info {
  margin-bottom: 2.5vmin;
}

.brand-panel {
  width: 31vmin;
}

.vertical-panel-content {
  padding-left: 2.5em;
  text-align: left;
}

.slider .arrow-left:before,
.slider .arrow-right:before {
  display: none;
}

.slider .arrow-left:after,
.slider .arrow-right:after {
  content: "";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  background: none;
  color: #787a7f;
  font-size: 2.5rem;
  opacity: 1;
  transition: all 0.3s ease-in;
}

.slider .arrow-left:hover:after,
.slider .arrow-right:hover:after {
  color: #ebad1a;
}

.slider .btn {
  padding: 1.3em 2.2em 1.25em !important;
}

.slider .btn i {
  display: none;
}

@media (min-width: 768px) {
  .arrow-left,
  .arrow-right {
    top: 41vmin;
  }
}

.arrow-right {
  margin-left: 0;
  left: 24vmin;
}

.arrow-left {
  left: 22vmin;
}

.slider .arrow-right:after {
  content: "";
}

.slotholder:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(9, 15, 40, 0.5);
}

@media (min-width: 991px) {
  .slotholder:after {
    background: linear-gradient(to left, rgba(9, 15, 40, 0.3) 0%, rgba(9, 15, 40, 0.3) 10%, rgba(9, 15, 40, 0.75) 10%, rgba(9, 15, 40, 0.75) 60%, rgba(9, 15, 40, 0.3) 60%, rgba(9, 15, 40, 0.3) 70%, rgba(9, 15, 40, 0.75) 70%, rgba(9, 15, 40, 0.75) 100%);
  }
}

@media (max-width: 991px) {
  .sm-break {
    display: block;
  }
}

.col-about-title {
  font-size: 1.575em;
}

.col-about-img {
  padding-left: 15px;
  left: 0;
}

.col-about-img .eye {
  width: 100px;
}

.col-about-img img {
  max-width: 100% !important;
}

@media (min-width: 768px) {
  .col-about-spec {
    padding: 0 2.4em;
  }
}

.service-item {
  margin-bottom: 1em;
}

.project figure:after {
  background-color: #123d71;
}

.project-zoom {
  background-color: #ebad1a;
  text-align: center;
}

.project-zoom:after {
  background: url(/img/icon-door.png) 0 0 no-repeat;
  width: 1.9em;
  top: 47%;
}

.project-zoom:before {
  content: "VEZI PRODUS";
  position: absolute;
  left: 50%;
  top: 61%;
  max-width: 70%;
  color: white;
  text-transform: uppercase;
  transform: translateX(-50%);
  font-family: "montserratsemibold";
  line-height: 1;
  font-size: 0.8em;
}

.info-item img {
  height: 55px;
  width: auto;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .info-item {
    text-align: center;
    margin-bottom: 30px;
  }
}

.experience .text-parallax {
  background-size: 100%;
}

.footer {
  font-size: 15px;
}

.footer .row {
  justify-content: space-between;
}

.footer .reserved a {
  color: #123d71;
}

.footer .links a {
  color: #a7a7a7;
  padding: 0 10px;
  display: inline-block;
}

@media (max-width: 768px) {
  .footer {
    margin-top: -30px;
  }

  .footer .reserved {
    order: 5;
  }

  .footer .links {
    width: 100%;
    margin-bottom: 10px;
  }

  .footer .links a {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
  }
}

.btn-shadow-2 {
  box-shadow: 0 15px 34px rgba(127, 134, 196, 0.5);
}

.col-address .float-left {
  min-width: 50%;
  margin-bottom: 15px;
}

.col-address h5 {
  font-family: "Oswald", sans-serif;
  line-height: 1;
  font-size: 1em;
  font-weight: normal;
  color: #123d71;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 0;
}

.col-address a {
  color: #757575;
}

.bg-gray {
  background-color: #f2f2f2 !important;
}

.categories .img {
  width: 100%;
  padding-bottom: 200%;
  background: #fff url("/img/soral-bg6.jpg") no-repeat center;
  background-size: cover;
}

.showroom-item {
  text-transform: uppercase;
  color: white;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.showroom-item.yellow .img {
  background: #fff url("/img/door-yellow.jpg") no-repeat center;
  background-size: cover;
}

.showroom-item.blue .img {
  background: #fff url("/img/door-blue2.jpg") no-repeat center;
  background-size: cover;
}

.showroom-item .img {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  padding-bottom: 100%;
  transition: all 0.4s ease-out;
}

.showroom-item .text {
  position: absolute;
  z-index: 3;
  bottom: 40px;
  left: 50px;
}

.showroom-item:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  padding-bottom: 100%;
  z-index: 2;
}

.showroom-item.yellow:after {
  background-image: linear-gradient(to bottom, rgba(235, 173, 26, 0.6) 0%, rgba(235, 173, 26, 0.9) 100%);
}

.showroom-item.blue:after {
  background-image: linear-gradient(to bottom, rgba(18, 61, 113, 0.6) 0%, rgba(18, 61, 113, 0.9) 100%);
}

.showroom-item .top {
  font-family: "montserratlight";
  font-size: 0.95rem;
  letter-spacing: 3px;
}

.showroom-item .bottom {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 1.9em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.showroom-item .top,
.showroom-item .bottom {
  line-height: 1.05;
  color: white !important;
}

.showroom-item:hover .img {
  transform: scale(1.1);
}

.main-projects,
.main-contacts {
  padding: 24vmin 0 24vmin;
}

.categories .fade-title-left {
  left: 21%;
}

.product-image {
  padding-bottom: 120%;
}

.products-carousel .project figure:after {
  background-color: transparent;
}

.spacer {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.desc__title:after {
  content: "";
  background-color: #ebad1a;
  display: block;
  width: 70px;
  height: 5px;
  margin-top: 5px;
  border-radius: 2px;
}

.desc__colors .color {
  width: calc(25% - 10px);
  height: 100px;
  float: left;
  margin: 0 10px 10px 0;
}

.desc__category {
  color: white !important;
  background-color: #ebad1a;
  display: inline-block;
}

.desc__text {
  font-size: 0.9rem;
}

.desc__info {
  font-size: 1rem;
}

.section-subtitle {
  max-width: 800px;
  margin: 15px auto 30px;
  text-align: center;
}

.pbox {
  text-decoration: none !important;
  margin: 30px 20px;
  display: inline-block;
  text-align: center;
  background-color: white;
  padding: 20px 0;
  box-shadow: 0 0 15px 0 rgba(200, 200, 200, 0.5);
}

.pbox a:hover {
  text-decoration: none;
}

.pbox__title {
  font-size: 1.3rem;
  color: #123d71 !important;
  margin-bottom: 10px;
  padding: 0 15px 5px 0;
  display: block;
}

.pbox__image {
  display: block;
  padding-bottom: 60%;
  background: #fff url("/img/usi10.jpg") center;
  background-size: cover;
}

.pbox__category {
  font-size: 0.9rem;
  color: white !important;
  background-color: #ebad1a;
  margin: 10px 25px 9px 25px;
  padding: 1px 3px;
  display: block;
}

.pbox__desc {
  font-size: 0.9rem;
  color: #747474 !important;
  padding: 0 25px;
  line-height: 1.5;
  text-align: justify;
}

.pbox__button {
  font-size: 0.9rem;
  margin: 10px 15px;
  border: 1px solid #ebad1a;
  padding: 8px 10px;
  border-radius: 20px;
  display: block;
}

.pbox__button:hover {
  background-color: #ebad1a;
  transition: all 0.2s ease-in-out;
  color: white;
}

.related-products .mfp-arrow-right:after {
  border-left: 17px solid #123d71;
}

.related-products .mfp-arrow-left:after {
  border-right: 17px solid #123d71;
}

@media (min-width: 768px) {
  .related-products .owl-next {
    right: -2.4em;
  }

  .related-products .owl-prev {
    left: -2.4em;
  }
}

.bg-vector {
  background: url("/img/vector-bg.png") right no-repeat;
  background-size: 500px auto;
}

.bg-vector.left {
  background: url("/img/vector-bg-rev.png") left no-repeat;
}

.fade:not(.show) {
  opacity: 1;
}

.modal {
  opacity: 0 !important;
  transition: opacity 200ms ease-in-out !important;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-dialog {
  transition: none !important;
}

.modal.in {
  opacity: 1 !important;
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
  }
}

.modal-body {
  padding-bottom: 5px;
}

.modal .close {
  font-size: 2rem;
  color: #123d71;
}

.address {
  padding: 40px 0;
}

.address__img {
  height: 50px;
  width: auto;
}

.address__head {
  margin-left: 10px;
  font-size: 1.3rem !important;
  letter-spacing: 3px;
}

@media (min-width: 992px) {
  .mx-neg {
    margin-left: -15px;
    margin-right: -15px;
  }

  .br-s {
    border-right: 1px solid #123d71;
  }
}

.smenu {
  box-shadow: 0 0 15px 0 rgba(200, 200, 200, 0.5);
  padding: 20px 25px;
}

.smenu__title {
  font-size: 2rem;
}

.smenu__option {
  display: block;
  color: #123d71;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 1.1em;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  padding: 3px 0;
}

@media (max-width: 768px) {
  .vertical-panel-info.vertical-panel-info {
    margin-bottom: 12.5vmin !important;
  }

  .brand-panel {
    height: 55.7vmin;
  }
}

.catalog {
  text-align: center;
  padding: 10px 15px 15px 15px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-out;
  margin-bottom: 30px;
}

.catalog:hover {
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}

.catalog__title {
  color: #123d71;
  font-weight: 700;
  margin-bottom: 5px;
}

.pbox2 {
  width: 100%;
}

.pbox2 .pbox__image {
  padding-bottom: 80%;
}

.pbox2 .pbox__desc {
  display: none;
}

.img-fluid,
.img-responsive {
  max-width: 100%;
  height: auto;
}

.project-details-item:nth-child(1) .project-details-title:before {
  right: -4.4em;
  left: auto;
}

.project-details-item .project-details-info {
  background-color: #123d71;
}

@media (min-width: 992px) and (max-width: 1300px) {
  .header-phone {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .navbar-desctop-menu.navbar-desctop-menu > li {
    margin-left: 1.5em;
  }
}

